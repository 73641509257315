import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"access-control-expose-headers":"*","timing-allow-origin":"*","date":"Wed, 04 Dec 2024 03:21:19 GMT","accept-ranges":"bytes","content-type":"text/plain; charset=utf-8","x-jsd-version":"1.0.8","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","x-served-by":"cache-fra-etou8220096-FRA, cache-bur-kbur8200117-BUR","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","x-cache":"HIT, HIT","cross-origin-resource-policy":"cross-origin","strict-transport-security":"max-age=31536000; includeSubDomains; preload","alt-svc":"h3=\":443\";ma=86400,h3-29=\":443\";ma=86400,h3-27=\":443\";ma=86400","x-jsd-version-type":"version","x-content-type-options":"nosniff","age":"1206628","vary":"Accept-Encoding","access-control-allow-origin":"*"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1733282479}